export const module_names = {
  static_module: 'Static',
  dashboard_module: 'Dashboard',
  users_module: 'Users',
  roles_module: 'Roles',
  profile_module: 'Profile',
  expense_module: 'Expenses',
  reimbursements_module: 'Reimbursements',
  cashNExpense_module: 'PattyCash',
  vendors_module: 'Vendors',
  clients_module: 'Clients',
  projects_module: 'Projects',
  billableaddons_module: 'Billable AddOns',
  invoice_module: 'Invoices',
  payments_module: 'Payments',
  assets_module: 'Assets',
  reports_module: 'Reports',
  proformainvoice_module: 'Proforma Invoices',
  settings_module: 'Primary Settings',
  contacts_module: 'Contacts',
  lead_module: 'Lead',
  followups_module: 'Followups',
  hiring_module: 'Hiring',
  leaves_module: 'Leaves',
  salary_module: 'Salary',
  opportunity_module: 'Opportunity',
  challan_module: 'Challan',
  prospect_module: 'Prospect',
  employee_module: 'Employee',
  dropdownsettings_module: 'Dropdown Settings',
  creditnote_module: 'Credit Note',
  package_module: 'Package',
};
