import { module_names } from './ModulesNameInDB';

export interface CustomRouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
  extralink: boolean;
  submenu: CustomRouteInfo[];
  module_name: string;
}

export const CUSTOM_ROUTES: CustomRouteInfo[] = [
  {
    path: '',
    title: 'Dashboard',
    icon: 'mdi mdi-view-dashboard',
    class: '',
    extralink: false,
    submenu: [
      {
        path: '/resource-planner/dashboard',
        title: 'Dashboard',
        icon: 'mdi mdi-view-dashboard',
        class: '',
        extralink: false,
        submenu: [],
        module_name: module_names.dashboard_module,
      },
    ],
    module_name: module_names.dashboard_module,
  },
  {
    path: '',
    title: 'CRM',
    icon: 'mdi mdi-chemical-weapon',
    class: 'has-arrow',
    extralink: false,
    submenu: [
      {
        path: '/resource-planner/leads',
        title: 'Leads',
        icon: 'mdi mdi-contacts',
        class: '',
        extralink: false,
        submenu: [],
        module_name: module_names.lead_module,
      },
      {
        path: '/resource-planner/prospect',
        title: 'Prospect',
        icon: 'mdi mdi-lan',
        class: '',
        extralink: false,
        submenu: [],
        module_name: module_names.prospect_module,
      },
      {
        path: '/resource-planner/opportunity',
        title: 'Opportunity',
        icon: 'mdi mdi-lightbulb-on',
        class: '',
        extralink: false,
        submenu: [],
        module_name: module_names.opportunity_module,
      },
      {
        path: '/resource-planner/followups',
        title: 'Followups',
        icon: 'mdi mdi-timeline-clock',
        class: '',
        extralink: false,
        submenu: [],
        module_name: module_names.followups_module,
      },
    ],
    module_name: module_names.static_module,
  },
  {
    path: '',
    title: 'Billing',
    icon: 'mdi mdi-library-plus',
    class: 'has-arrow',
    extralink: false,
    submenu: [
      {
        path: '/resource-planner/clients',
        title: 'Clients',
        icon: 'mdi mdi-account-multiple-plus',
        class: '',
        extralink: false,
        submenu: [],
        module_name: module_names.clients_module,
      },
      {
        path: '/resource-planner/projects',
        title: 'Projects',
        icon: 'mdi mdi-pandora',
        class: '',
        extralink: false,
        submenu: [],
        module_name: module_names.projects_module,
      },
      {
        path: '/resource-planner/packages',
        title: 'Packages',
        icon: 'mdi mdi-package',
        class: '',
        extralink: false,
        submenu: [],
        module_name: module_names.package_module,
      },
      {
        path: '/resource-planner/billables',
        title: 'Billable AddOns',
        icon: 'mdi mdi-plus-box',
        class: '',
        extralink: false,
        submenu: [],
        module_name: module_names.billableaddons_module,
      },
      {
        path: '/resource-planner/proforma-invoice',
        title: 'Proforma Invoice',
        icon: 'mdi mdi-invoice',
        class: '',
        extralink: false,
        submenu: [],
        module_name: module_names.proformainvoice_module,
      },
      {
        path: '/resource-planner/invoice',
        title: 'Invoice',
        icon: 'mdi mdi-invoice-check',
        class: '',
        extralink: false,
        submenu: [],
        module_name: module_names.invoice_module,
      },
      {
        path: '/resource-planner/payment',
        title: 'Payments',
        icon: 'mdi mdi-cash',
        class: '',
        extralink: false,
        submenu: [],
        module_name: module_names.payments_module,
      },
      {
        path: '/resource-planner/creditnote',
        title: 'Credit Note',
        icon: 'mdi mdi-note-text',
        class: '',
        extralink: false,
        submenu: [],
        module_name: module_names.creditnote_module,
      },
    ],
    module_name: module_names.static_module,
  },
  {
    path: '',
    title: 'HRM',
    icon: 'mdi mdi-human-child',
    class: 'has-arrow',
    extralink: false,
    submenu: [
      {
        path: '/resource-planner/hiring',
        title: 'Hiring',
        icon: 'mdi mdi-laptop',
        class: '',
        extralink: false,
        submenu: [],
        module_name: module_names.hiring_module,
      },
      {
        path: '/resource-planner/employee',
        title: 'Employee',
        icon: 'mdi mdi-account-box-outline',
        class: '',
        extralink: false,
        submenu: [],
        module_name: module_names.employee_module,
      },
      {
        path: '/resource-planner/leaves',
        title: 'Leaves',
        icon: 'mdi mdi-calendar-clock',
        class: '',
        extralink: false,
        submenu: [],
        module_name: module_names.leaves_module,
      },
      {
        path: '/resource-planner/salary',
        title: 'Salary',
        icon: 'mdi mdi-cash-100',
        class: '',
        extralink: false,
        submenu: [],
        module_name: module_names.salary_module,
      },
      {
        path: '/resource-planner/assets',
        title: 'Assets',
        icon: 'mdi mdi-developer-board',
        class: '',
        extralink: false,
        submenu: [],
        module_name: module_names.assets_module,
      },
    ],
    module_name: module_names.static_module,
  },
  {
    path: '',
    title: 'BookKeeping',
    icon: 'mdi mdi-minus-box',
    class: 'has-arrow',
    extralink: false,
    submenu: [
      {
        path: '/resource-planner/expenses',
        title: 'Expenses',
        icon: 'mdi mdi-trending-down',
        class: '',
        extralink: false,
        submenu: [],
        module_name: module_names.expense_module,
      },
      {
        path: '/resource-planner/cash-expense-balance',
        title: 'Cash Expense & Balance',
        icon: 'mdi mdi-scale-balance',
        class: '',
        extralink: false,
        submenu: [],
        module_name: module_names.cashNExpense_module,
      },
      {
        path: '/resource-planner/reimbursements',
        title: 'Reimbursement',
        icon: 'mdi mdi-cash-multiple',
        class: '',
        extralink: false,
        submenu: [],
        module_name: module_names.reimbursements_module,
      },
      {
        path: '/resource-planner/challan',
        title: 'Challan',
        icon: 'mdi mdi-receipt-text',
        class: '',
        extralink: false,
        submenu: [],
        module_name: module_names.challan_module,
      },
      {
        path: '/resource-planner/suppliers',
        title: 'Vendors/Suppliers',
        icon: 'mdi mdi-account-multiple-plus',
        class: '',
        extralink: false,
        submenu: [],
        module_name: module_names.vendors_module,
      },
    ],
    module_name: module_names.static_module,
  },
  {
    path: '',
    title: 'Users',
    icon: 'mdi mdi-account-check',
    class: 'has-arrow',
    extralink: false,
    submenu: [
      {
        path: '/resource-planner/authorities',
        title: 'Roles',
        icon: 'mdi mdi-account-settings',
        class: '',
        extralink: false,
        submenu: [],
        module_name: module_names.roles_module,
      },
      {
        path: '/resource-planner/users',
        title: 'Users',
        icon: 'mdi mdi-account-multiple',
        class: '',
        extralink: false,
        submenu: [],
        module_name: module_names.users_module,
      },
    ],
    module_name: module_names.static_module,
  },
  {
    path: '',
    title: 'Statements',
    icon: 'mdi mdi-equal-box',
    class: '',
    extralink: false,
    submenu: [
      {
        path: '/resource-planner/reports',
        title: 'Reports',
        icon: 'mdi mdi-equal-box',
        class: '',
        extralink: false,
        submenu: [],
        module_name: module_names.reports_module,
      },
    ],
    module_name: module_names.reports_module,
  },
  {
    path: '',
    title: 'Settings',
    icon: 'mdi mdi-settings',
    class: 'has-arrow',
    extralink: false,
    submenu: [
      {
        path: '/resource-planner/settings',
        title: 'Primary Settings',
        icon: 'mdi mdi-cog',
        class: '',
        extralink: false,
        submenu: [],
        module_name: module_names.settings_module,
      },
      {
        path: '/resource-planner/general-settings',
        title: 'General Settings',
        icon: 'mdi mdi-cogs',
        class: '',
        extralink: false,
        submenu: [],
        module_name: module_names.dropdownsettings_module,
      },
    ],
    module_name: module_names.static_module,
  },
];
