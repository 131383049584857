import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

interface BroadcastEvent {
  key: any;
  data?: any;
}
@Injectable({
  providedIn: 'root',
})
export class CommunicationService {
  private rowData = new Subject<BroadcastEvent>();
  private refreshDataFilter = new Subject<any>();
  private sidebarEvent = new Subject<any>();
  private themeToggleEvent = new Subject<any>();

  constructor(private http: HttpClient) {
    this.refreshDataFilter = new EventEmitter<any>();
    this.sidebarEvent = new EventEmitter<any>();
    this.themeToggleEvent = new EventEmitter<any>();
  }

  sendSidebarEvent() {
    
    this.sidebarEvent.next(true);
  }

  captureSidebarEvent() {
    
    return this.sidebarEvent.asObservable();
  }

  sendToggleThemeEvent() {
    this.themeToggleEvent.next(true);
  }

  captureToggleThemeEvent() {
    return this.themeToggleEvent.asObservable();
  }

  setRefreshDataFilter() {
    this.refreshDataFilter.next(true);
  }

  getRefreshDataFilter() {
    return this.refreshDataFilter.asObservable();
  }

  setRecordData(key: any, data?: any) {
    
    this.rowData.next({ key, data });
  }

  getRecordData<T>(key: any): Observable<T> {
    
    return this.rowData.asObservable().pipe(
      filter((event) => event.key === key),
      map((event) => <T>event.data)
    );
  }

  RemoveDuplicates = (array: any, key: any) => {
    return array.reduce((arr: any, item: any) => {
      const removed = arr.filter((i: any) => i[key] !== item[key]);
      return [...removed, item];
    }, []);
  };

  uploadFile(formData: FormData) {
    let uploadURL = environment.URL + 'file/upload';
    return this.http.post(uploadURL, formData);
  }

  downloadUrl(filePath: any) {
    return environment.URL + 'file/download?filePath=' + filePath;
  }

  retrieveURL(filePath: any) {
    return environment.URL + 'file/retrieve?filePath=' + filePath;
  }

  downloadFile(filePath: any) {
    let downloadUrl = environment.URL + 'file/download?filePath=' + filePath;
    var downloadFrame = document.createElement('iframe');
    downloadFrame.setAttribute('src', downloadUrl);
    downloadFrame.setAttribute('class', 'screenReaderText');
    document.body.appendChild(downloadFrame);
  }

  downloadFileByURL(fileURL: any) {
    let downloadUrl = fileURL;
    var downloadFrame = document.createElement('iframe');
    downloadFrame.setAttribute('src', downloadUrl);
    downloadFrame.setAttribute('class', 'screenReaderText');
    document.body.appendChild(downloadFrame);
  }

  getFormattedDate(timestamp: any) {
    let fullDate = Number(timestamp);
    if (fullDate != null && fullDate != 0 && fullDate != undefined) {
      let date = new Date(fullDate);
      

      return date;
    } else {
      return null;
    }
  }

  getDateStamp(fullDate: any) {
    
    if (fullDate != null && fullDate != 0 && fullDate != undefined) {
      let dateStamp = new Date(fullDate).getTime();
      
      return dateStamp;
    } else {
      return null;
    }
  }

  getNgbDateStamp(dateObj) {
    if (dateObj && dateObj.year && dateObj.month && dateObj.day) {
      // Create a Date object with the provided year, month (0-based index), and day.
      // Note: In JavaScript, months are 0-indexed (January is 0, February is 1, etc.)
      let date = new Date(dateObj.year, dateObj.month - 1, dateObj.day);
      let dateStamp = date.getTime(); // Get the timestamp in milliseconds since epoch
      
      return dateStamp;
    } else {
      return null;
    }
  }

  // getNgbTimeStamp(timeObj) {
  //   if (
  //     timeObj &&
  //     timeObj.hour !== undefined &&
  //     timeObj.minute !== undefined &&
  //     timeObj.second !== undefined
  //   ) {
  //     // Create a Date object with the provided hour, minute, and second.
  //     // The date parts (year, month, day) can be any valid date since we are interested in time parts.
  //     let date = new Date();
  //     date.setHours(timeObj.hour, timeObj.minute, timeObj.second, 0); // Set hours, minutes, seconds, and milliseconds (0)
  //     let timeStamp = date.getTime(); // Get the timestamp in milliseconds since epoch
  //     
  //     return timeStamp;
  //   } else {
  //     return null;
  //   }
  // }

  getNgbFormattedDate(timestampStr) {
    let timestamp = parseInt(timestampStr, 10); // Convert timestamp string to number
    if (!isNaN(timestamp) && timestamp !== 0 && timestamp !== undefined) {
      let date = new Date(timestamp);
      let year = date.getFullYear();
      let month = date.getMonth() + 1; // Month is zero-indexed, so we don't need to subtract 1
      let day = date.getDate();
      return { year, month, day };
    } else {
      return null;
    }
  }

  getNgbTimeStamp(dateTimeObj) {
    if (
      dateTimeObj &&
      dateTimeObj.date &&
      dateTimeObj.date.year !== undefined &&
      dateTimeObj.date.month !== undefined &&
      dateTimeObj.date.day !== undefined &&
      dateTimeObj.time &&
      dateTimeObj.time.hour !== undefined &&
      dateTimeObj.time.minute !== undefined &&
      dateTimeObj.time.second !== undefined
    ) {
      let date = new Date(
        dateTimeObj.date.year,
        dateTimeObj.date.month - 1,
        dateTimeObj.date.day,
        dateTimeObj.time.hour,
        dateTimeObj.time.minute,
        dateTimeObj.time.second
      );
      let timeStamp = date.getTime(); // Get the timestamp in milliseconds since epoch
      return timeStamp;
    } else {
      return null;
    }
  }

  getFloatValue(num: any) {
    let returnValue = +(Math.round(+(num + 'e' + 2)) + 'e' + -2);
    if (returnValue) {
      return Number(returnValue);
    } else {
      return Number((+(Math.round(+(0 + 'e' + 2)) + 'e' + -2)).toFixed(2));
    }
  }

  getBase64(file: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  acceptedFilesExtension(base64String: any) {
    let fileExtension = base64String.split(';')[0].split('/')[1];
    if (fileExtension == 'vnd.ms-excel') {
      fileExtension = 'xls';
    } else if (
      fileExtension == 'vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ) {
      fileExtension = 'xlsx';
    } else if (
      fileExtension ==
      'vnd.openxmlformats-officedocument.wordprocessingml.document'
    ) {
      fileExtension = 'docx';
    } else if (fileExtension == 'msword') {
      fileExtension = 'doc';
    } else if ((fileExtension = 'image/jpeg')) {
      fileExtension = 'jpeg';
    } else if ((fileExtension = 'image/png')) {
      fileExtension = 'png';
    } else if ((fileExtension = 'image/jpg')) {
      fileExtension = 'jpg';
    } else {
      fileExtension = null;
    }
    return fileExtension;
  }

  getValueById(_id: any, array: any) {
    return array.find((o: any) => o._id.toString() === _id.toString());
  }

  validateForm(recordForm: any) {
    for (const key in recordForm.controls) {
      if (Object.prototype.hasOwnProperty.call(recordForm.controls, key)) {
        const element = recordForm.controls[key];
        if (element.invalid && element.required) {
        }
      }
    }
  }
}
