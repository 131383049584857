import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { module_names } from './shared/sidebar/ModulesNameInDB';
import {
  CUSTOM_ROUTES,
  CustomRouteInfo,
} from './shared/sidebar/resource-menu-items';
import { CUSTOM_MODULE_TAB } from './views/common/components/common-modules-tab';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  openRecordDetails: any;
  tag_key: String = '@Resource.Planner_2020';

  constructor(private http: HttpClient) {
    this.openRecordDetails = new EventEmitter<Boolean>();
  }

  openFormSidebar() {
    this.openRecordDetails.emit(true);
  }

  closeFormSidebar() {
    this.openRecordDetails.emit(false);
  }

  authenticateCredentials(url: any, loginParams: any) {
    return this.http.post(url, loginParams);
  }

  getSessionDetails() {
    let encryptedUserDetails = sessionStorage.getItem('user_details');

    if (encryptedUserDetails != null && encryptedUserDetails != undefined) {
      const decryptWithAES = (ciphertext: any, tag_key: any) => {
        const passphrase = tag_key;
        const bytes = CryptoJS.AES.decrypt(ciphertext, passphrase);
        const originalText = bytes.toString(CryptoJS.enc.Utf8);
        return originalText;
      };
      let decryptedUserDetails = decryptWithAES(
        sessionStorage.getItem('user_details'),
        this.tag_key
      );


      return {
        isSessionAlive: true,
        user_details: JSON.parse(decryptedUserDetails),
      };
    } else {
      return { isSessionAlive: false, user_details: null };
    }
  }

  getRolesNPermissions() {
    let sidebarnavItems: CustomRouteInfo[] = [];
    let sessionDetails = this.getSessionDetails();
    let UsersModulesNPermissions =
      sessionDetails.user_details.roleDetails.moduleNPermissions;
    let ROUTES_CLONE = CUSTOM_ROUTES;
    ROUTES_CLONE.forEach((ModuleElement: any) => {
      const indexOfModule = UsersModulesNPermissions.findIndex(
        (permissionElement: any) =>
          permissionElement.moduleName == ModuleElement.module_name
      );
      let mainmoduleItemByIndex = UsersModulesNPermissions[indexOfModule];
      if (mainmoduleItemByIndex) {
        if (ModuleElement.module_name == mainmoduleItemByIndex.moduleName) {
          let prepareSubMenu = [];
          if (ModuleElement.submenu.length > 0) {
            ModuleElement.submenu.forEach((SubModuleElement: any) => {
              const indexOfModule = UsersModulesNPermissions.findIndex(
                (permissionElement: any) =>
                  permissionElement.moduleName == SubModuleElement.module_name
              );
              let permissionCount = 0;
              let submoduleItemByIndex =
                UsersModulesNPermissions[indexOfModule];
              if (submoduleItemByIndex) {
                submoduleItemByIndex.functionalities.forEach(
                  (moduleItemByIndexElement: any) => {
                    if (moduleItemByIndexElement.isPermitted == true) {
                      permissionCount = permissionCount + 1;
                    }
                  }
                );
                if (permissionCount > 0) {
                  if (
                    submoduleItemByIndex.moduleName ==
                    SubModuleElement.module_name
                  ) {
                    prepareSubMenu.push(SubModuleElement);
                  }
                  ModuleElement.submenu = prepareSubMenu;
                  sidebarnavItems.push(ModuleElement);
                }
              }
            });
          } else {
            let permissionCount = 0;
            mainmoduleItemByIndex.functionalities.forEach(
              (moduleItemByIndexElement: any) => {
                if (moduleItemByIndexElement.isPermitted == true) {
                  permissionCount = permissionCount + 1;
                }
              }
            );
            if (permissionCount > 0) {
              sidebarnavItems.push(ModuleElement);
            }
          }
        }
      } else {
        if (ModuleElement.module_name == module_names.static_module) {
          let prepareSubMenu: any = [];
          ModuleElement.submenu.forEach((SubModuleElement: any) => {
            const indexOfModule = UsersModulesNPermissions.findIndex(
              (permissionElement: any) =>
                permissionElement.moduleName == SubModuleElement.module_name
            );

            //   " permissionCount = permissionCount + 1;",
            //   SubModuleElement.module_name
            // );
            let permissionCount = 0;
            let moduleItemByIndex = UsersModulesNPermissions[indexOfModule];
            if (moduleItemByIndex) {
              moduleItemByIndex.functionalities.forEach(
                (moduleItemByIndexElement: any) => {
                  if (moduleItemByIndexElement.isPermitted == true) {
                    permissionCount = permissionCount + 1;
                  }
                }
              );
              if (permissionCount > 0) {
                if (
                  moduleItemByIndex.moduleName == SubModuleElement.module_name
                ) {
                  prepareSubMenu.push(SubModuleElement);
                }
              }
            }
          });
          ModuleElement.submenu = prepareSubMenu;
          if (ModuleElement.submenu.length > 0) {
            sidebarnavItems.push(ModuleElement);
          }
        }
      }
    });
    return sidebarnavItems;
  }

  hasAccess(module: any, permission: any) {
    let sessionDetails = this.getSessionDetails();
    let UsersModulesNPermissions =
      sessionDetails.user_details.roleDetails.moduleNPermissions;

    //   "user_details.roleDetails.moduleNPermissions",
    //   sessionDetails.user_details.roleDetails.moduleNPermissions
    // );

    //   "UsersModulesNPermissions.find",
    //   sessionDetails.user_details.roleDetails.moduleNPermissions
    // );
    const UserModule: any = UsersModulesNPermissions.find(
      (access: any) => access.moduleName === module
    );
    if (UserModule == null || UserModule == undefined) {
      return false;
    } else {
      const UserPermission: any = UserModule.functionalities.find(
        (access: any) => access.functionalityName === permission
      );
      if (UserPermission == null || UserPermission == undefined) {
        return false;
      } else {
        return UserPermission.isPermitted;
      }
    }
  }

  getPermittedFunctionalities(module: any) {
    let sessionDetails = this.getSessionDetails();
    let UsersModulesNPermissions =
      sessionDetails.user_details.roleDetails.moduleNPermissions;
    const UserModule: any = UsersModulesNPermissions.find(
      (access: any) => access.moduleName === module
    );
    return UserModule.functionalities;
  }


  getCurrentUserId() {
    return this.getSessionDetails().user_details.userData._id;
  }
  getCurrentUserName() {
    return this.getSessionDetails().user_details.userData.username;
  }

  getModuleTabs(module: any) {
    let moduleTabs = CUSTOM_MODULE_TAB;
    return moduleTabs.find((element: any) => element.module_name === module);
  }
}
