import { module_names } from '../../../shared/sidebar/ModulesNameInDB';

export const CUSTOM_MODULE_TAB = [
  {
    moduleTabId: [
      module_names.users_module.concat('details'),
      module_names.users_module.concat('changelogs'),
      module_names.users_module.concat('media'),
      module_names.users_module.concat('userstatement'),
    ],
    module_name: module_names.users_module,
  },
  {
    moduleTabId: [
      module_names.employee_module.concat('details'),
      module_names.employee_module.concat('changelogs'),
      module_names.employee_module.concat('media'),
      module_names.employee_module.concat('userstatement'),
      module_names.employee_module.concat('usershistory'),
    ],
    module_name: module_names.employee_module,
  },
  {
    moduleTabId: [
      module_names.expense_module.concat('details'),
      module_names.expense_module.concat('changelogs'),
      module_names.expense_module.concat('media'),
    ],
    module_name: module_names.expense_module,
  },
  {
    moduleTabId: [
      module_names.vendors_module.concat('details'),
      module_names.vendors_module.concat('changelogs'),
      module_names.vendors_module.concat('media'),
    ],
    module_name: module_names.vendors_module,
  },
  {
    moduleTabId: [
      module_names.reimbursements_module.concat('details'),
      module_names.reimbursements_module.concat('changelogs'),
      module_names.reimbursements_module.concat('media'),
    ],
    module_name: module_names.reimbursements_module,
  },
  {
    moduleTabId: [
      module_names.clients_module.concat('details'),
      module_names.clients_module.concat('changelogs'),
      module_names.clients_module.concat('media'),
      module_names.clients_module.concat('clientstatement'),
    ],
    module_name: module_names.clients_module,
  },
  {
    moduleTabId: [
      module_names.projects_module.concat('details'),
      module_names.projects_module.concat('changelogs'),
      module_names.projects_module.concat('media'),
      module_names.clients_module.concat('projectstatement'),
    ],
    module_name: module_names.projects_module,
  },
  {
    moduleTabId: [
      module_names.billableaddons_module.concat('details'),
      module_names.billableaddons_module.concat('changelogs'),
      module_names.billableaddons_module.concat('media'),
    ],
    module_name: module_names.billableaddons_module,
  },
  // {
  //   moduleTabId: [
  //     module_names.contacts_module.concat("details"),
  //     module_names.contacts_module.concat("changelogs"),
  //     module_names.contacts_module.concat("media"),
  //   ],
  //   module_name: module_names.contacts_module,
  // },
  {
    moduleTabId: [
      module_names.lead_module.concat('details'),
      module_names.lead_module.concat('changelogs'),
      module_names.lead_module.concat('media'),
    ],
    module_name: module_names.lead_module,
  },

  {
    moduleTabId: [
      module_names.prospect_module.concat('details'),
      module_names.prospect_module.concat('changelogs'),
      module_names.prospect_module.concat('media'),
      module_names.prospect_module.concat('email'),
    ],
    module_name: module_names.prospect_module,
  },
  {
    moduleTabId: [
      module_names.followups_module.concat('details'),
      module_names.followups_module.concat('changelogs'),
      module_names.followups_module.concat('media'),
    ],
    module_name: module_names.followups_module,
  },
  {
    moduleTabId: [
      module_names.proformainvoice_module.concat('details'),
      module_names.proformainvoice_module.concat('changelogs'),
      module_names.proformainvoice_module.concat('media'),
      module_names.proformainvoice_module.concat('invoice_view'),
    ],
    module_name: module_names.proformainvoice_module,
  },
  {
    moduleTabId: [
      module_names.invoice_module.concat('details'),
      module_names.invoice_module.concat('changelogs'),
      module_names.invoice_module.concat('media'),
      module_names.invoice_module.concat('invoice_view'),
    ],
    module_name: module_names.invoice_module,
  },
  {
    moduleTabId: [
      module_names.payments_module.concat('details'),
      module_names.payments_module.concat('changelogs'),
      module_names.payments_module.concat('media'),
    ],
    module_name: module_names.payments_module,
  },
  {
    moduleTabId: [
      module_names.assets_module.concat('details'),
      module_names.assets_module.concat('changelogs'),
      module_names.assets_module.concat('media'),
    ],
    module_name: module_names.assets_module,
  },

  {
    moduleTabId: [
      module_names.reports_module.concat('details'),
      module_names.reports_module.concat('changelogs'),
      module_names.reports_module.concat('media'),
    ],
    module_name: module_names.reports_module,
  },
  {
    moduleTabId: [
      module_names.hiring_module.concat('details'),
      module_names.hiring_module.concat('changelogs'),
      module_names.hiring_module.concat('media'),
    ],
    module_name: module_names.hiring_module,
  },
  {
    moduleTabId: [
      module_names.leaves_module.concat('details'),
      module_names.leaves_module.concat('changelogs'),
      module_names.leaves_module.concat('media'),
    ],
    module_name: module_names.leaves_module,
  },
  {
    moduleTabId: [
      module_names.salary_module.concat('details'),
      module_names.salary_module.concat('changelogs'),
      module_names.salary_module.concat('media'),
      module_names.salary_module.concat('view'),
    ],
    module_name: module_names.salary_module,
  },
  {
    moduleTabId: [
      module_names.opportunity_module.concat('details'),
      module_names.opportunity_module.concat('changelogs'),
      module_names.opportunity_module.concat('media'),
      module_names.prospect_module.concat('email'),
    ],
    module_name: module_names.opportunity_module,
  },

  {
    moduleTabId: [
      module_names.challan_module.concat('details'),
      module_names.challan_module.concat('changelogs'),
      module_names.challan_module.concat('media'),
    ],
    module_name: module_names.challan_module,
  },
];
